/* App-global styles, kept at minimum. */

body { /* Body takes the entire page, ahs reasonable defaults. */
  height: 100vh;
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  color: black;
  background-color: white;
}

#root {  /* Put everything into a full-page flex container. */
    height: 100%;
    display: flex;
    flex-direction: column;
    flex-direction: column;
    align-items: stretch;
    padding: 0;
    margin: 0;
}
