@import url(./fonts/fonts.css);

.row {
  display: flex;
  flex-direction: row;
  margin-bottom: 24px;
}
.column {
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
}
.license {
  padding: 30px 0;
  width: 480px;
  margin-left: calc(50% - 250px);
  font-family: 'SFProText-Regular';
  font-size: 16px;
  font-weight: normal;
  color: rgb(51, 49, 48);
  line-height: 24px; 
  letter-spacing: 0.15px;
}
.line {
  border: 1px solid rgba(211, 211, 211, .5);
  margin-bottom: 36px;
}
.clickotine {
  width: 36px;
  height: 36px;
}
.logo {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 160px;
  margin-left: calc(50% - 80px);
}
.logoText {
  color: rgb(51, 49, 48);
  font-family: 'SFProText-Medium';
  font-size: 20px;
  font-weight: 500;
  height: 24px;
  letter-spacing: 0.15px;
  width: 110px;
  margin-left: 8px;
}
.code-frame {
  height: 93px;
  margin-left: calc(50% - 240px);
  margin-top: 24px;
  margin-bottom: 12px;
  background-color: rgba(142, 142, 147, 0.24);
  border-radius: 16px;
  text-align: center;
  font-size: 24px;
}
.code {
  margin-top: 33px;
  letter-spacing: 15px;
}
.email {
  margin-top: 33px;
  letter-spacing: 0.15px;
  font-size: 18px;
}
.title {
  color: rgb(51, 49, 48);
  font-family: 'SFProText-Semibold';
  font-size: 34px;
  font-weight: 600;
  height: 40px;
  letter-spacing: 0px;
  margin-bottom: 16px;
}
.subtitle {
  font-family: 'SFProText-Semibold';
  font-size: 24px;
  font-weight: 600;
  height: 29px;
  letter-spacing: 0px;
  margin-bottom: 16px;
}
.error {
  font-family: 'SFProText-Medium';
  font-weight: 500;
}
.error-icon {
  height: 257.97px;
  width: 354.83px;
  margin-left: calc(50% - 177px);
}
.error-text {
  margin-bottom: 12px;
}
.error-img {
  width: 280px;
  height: 48px;
  margin-top: 12px;
  margin-bottom: 48px;
  margin-left: calc(50% - 140px);;
}
.instruction {
  display: -webkit-box;
  width: 900px;
  margin-left: calc(50% - 450px);
  font-family: 'SFProText-Regular';
  letter-spacing: 0.15px;
  font-size: 16px;
  font-weight: normal;
  line-height: 24px;
  color: rgb(51, 49, 48);
}
.instruction-step {
  margin-left: 20px;
  margin-bottom: 12px;
}
.instruction-text {
  margin-bottom: 16px;
  width: 264px;
}
.frame-background {
  width: 264px;
  height: 420px;
  background-color: #e0e0e0;
  border-radius: 20px;
  text-align: center;
}
.frame {
  margin-top: 16px;
  height: 388px;
  width: 192px;
}
.app-store-logo {
  width: 146px;
  height: 49px;
  fill: #000000;
  margin-bottom: -30px;
  
}
.google-play-logo {
  margin-top: -11px;
  width: 190px;
  height: 70px;
  margin-bottom: -30px;
}
.mobile-download {
  display: none;
}
.link {
  color: rgb(0, 145, 255);
  text-decoration: none;
}
.footer {
  margin-top: 64px;
  font-size: 12px;
  text-align: center;
}
@media only screen and (max-width: 600px) {
  .license {
    width: 327px;
    margin-left: calc(50% - 163px);
  }
  .title {
    font-size: 24px;
    height: 29px;
  }
  .subtitle {
    width: 327px;
    height: 58px;
    margin-bottom: 0;
  }
  .code-frame {
    height: 76px;
    width: 327px;
    margin-left: calc(50% - 163px);
  }
  .code {
    margin-top: 25px;
  }
  .instruction {
    display: inline;
    width: 327px;
    margin-left: calc(50% - 163px);
    margin-top: -25px;
    margin-bottom: -30px;
  }
  .instruction-step {
    margin-left: 0px;
    margin-bottom: 36px;
  }
  .instruction-text {
    width: 327px;
    margin-left: calc(50% - 163px);
  }
  .frame-background {
    width: 327px;
    margin-left: calc(50% - 163px);
  }
  .mobile-download {
    display: flex;
    flex-direction: column;
  }
  .error-img {
    width: 327px;
    height: 48px;
    margin-left: calc(50% - 163px);;
  }
}

