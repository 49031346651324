@font-face {
  font-family: 'SFProText-Regular';
  src: url('SFProText-Regular.svg#SFProText-Regular') format('svg'),
        url('SFProText-Regular.ttf') format('truetype'),
        url('SFProText-Regular.woff') format('woff'),
        url('SFProText-Regular.woff2') format('woff2'),
        url('SFProText-Regular.eot'),
        url('SFProText-Regular.eot?#iefix') format('embedded-opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'SFProText-Semibold';
  src: url('SFProText-Semibold.svg#SFProText-Semibold') format('svg'),
        url('SFProText-Semibold.ttf') format('truetype'),
        url('SFProText-Semibold.woff') format('woff'),
        url('SFProText-Semibold.woff2') format('woff2'),
        url('SFProText-Semibold.eot'),
        url('SFProText-Semibold.eot?#iefix') format('embedded-opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'SFProText-Medium';
  src: url('SFProText-Medium.svg#SFProText-Medium') format('svg'),
        url('SFProText-Medium.ttf') format('truetype'),
        url('SFProText-Medium.woff') format('woff'),
        url('SFProText-Medium.woff2') format('woff2'),
        url('SFProText-Medium.eot'),
        url('SFProText-Medium.eot?#iefix') format('embedded-opentype');
  font-weight: normal;
  font-style: normal;
}
  